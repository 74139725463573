import styled from "styled-components"

export const PureButton = styled.button`
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  background: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Button = styled.button`
  text-decoration: none;
  background-color: var(--button-bg);
  color: var(--button-color);
  text-transform: uppercase;
  padding: 8.5px 12px;
  border-radius: 6px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: 13px;
  line-height: 15px;

  transition: 0.3s;

  &:hover {
    background-color: var(--button-bg-hover);
  }

  &:active {
    background-color: var(--button-bg-active);
  }
`

export const LinkButton = styled.a`
  width: fit-content;
  text-decoration: none !important;
  background-color: var(--button-bg);
  color: var(--button-color) !important;
  text-transform: uppercase;
  padding: 24px 20px;
  border-radius: 6px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  line-height: 15px;
  font-size: 13px;

  transition: 0.3s;

  &:hover {
    background-color: var(--button-bg-hover);
  }

  &:active {
    background-color: var(--button-bg-active);
  }
`

export const HEADER_HEIGHT = {
  DESKTOP: 64,
  TABLET: 56,
  MOBILE: 48,
}

export const BREAKPOINTS = {
  TABLET: 1200,
  MOBILE: 600,
}

export const FONT = {
  PRIMARY: "'Open Sans', sans-serif",
  SECONDARY: "Montserrat, sans-serif",
}

export const getHeaderHeight = () => {
  const width = window.innerWidth
  if (width > BREAKPOINTS.TABLET) return HEADER_HEIGHT.DESKTOP
  if (width > BREAKPOINTS.MOBILE) return HEADER_HEIGHT.TABLET
  return HEADER_HEIGHT.MOBILE
}

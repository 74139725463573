import styled, { css } from "styled-components"

import { FONT } from "../../../const"

const commonStyle = css`
  color: var(--text-color);
`

const commonHeadingStyle = css`
  &,
  a {
    color: var(--title-color);
    font-family: ${FONT.SECONDARY};
  }

  a.anchor {
    margin: 0;
  }

  &:hover {
    a.anchor {
      opacity: 1;
    }
  }

  a:hover {
    text-decoration: none;
  }
`

export const H1 = styled.h1`
  ${commonStyle}
  ${commonHeadingStyle}

  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
`

export const H2 = styled.h2`
  ${commonStyle}
  ${commonHeadingStyle}

  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 0;
`

export const H3 = styled.h3`
  ${commonStyle}
  ${commonHeadingStyle}

  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-top: 32px;
  margin-bottom: 0;
`

export const H4 = styled(H3)

export const H5 = styled(H3)

export const H6 = styled(H3)

const codeStyle = css`
  color: var(--code-piece-text);
  background-color: var(--code-piece-bg);
  border: 1px solid var(--code-piece-border);
  padding: 0 6px;
  border-radius: 4px;

  box-sizing: border-box;
  display: inline-block;
  width: fit-content;
  vertical-align: middle;
  line-height: 24px;
  min-height: 24px;

  font-family: Consolas, monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin: 0 4px;
`

export const P = styled.p`
  ${commonStyle}

  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  margin-top: 12px;
  margin-bottom: 12px;

  code {
    ${codeStyle}
  }
`

export const Img = styled.img`
  max-width: 100%;
  margin-top: 12px;
`

export const Code = styled.code`
  ${codeStyle}
`

const commonListStyle = css`
  padding: 0;
  margin: 0;
  margin-top: 8px;
`

export const Li = styled.li`
  ${commonStyle}

  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 8px;
  min-height: 28px;

  code {
    ${codeStyle}
  }

  ${P} {
    margin: 0;
  }
`

export const Ul = styled.ul`
  ${commonListStyle}
  list-style: none;

  ${Li} {
    padding-left: 20px;
  }

  > ${Li}:before {
    content: "";
    display: block;
    position: absolute;
    width: 4px;
    height: 4px;
    min-width: 4px;
    min-height: 4px;
    left: 6px;
    top: 13px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: var(--text-color);
  }
`

export const Ol = styled.ol`
  ${commonListStyle}
  list-style-type: none;
  counter-reset: num;

  > ${Li}:before {
    content: counter(num) ". ";
    counter-increment: num;
    font-size: 15px;
    line-height: 28px;
    position: absolute;
    left: 0;
  }
`

export const Strong = styled.strong``

import React from "react"
import { Card } from "./styled"

const TutorialCard = ({ children, file, lines = [], onlyText = false }) => {
  if (lines.length === 0 && !onlyText) {
    return <Card>{children}</Card>
  }

  return onlyText ? (
    <Card onlyText>{children}</Card>
  ) : (
    <Card data-card data-lines={JSON.stringify(lines)} data-file={file}>
      {children}
    </Card>
  )
}

export default TutorialCard

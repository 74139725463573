exports.onPreRouteUpdate = () => {
  const el = document.getElementById("sidebar")
  localStorage.setItem("sidebar_scroll", el?.scrollTop)
}

exports.onRouteUpdate = () => {
  const el = document.getElementById("sidebar")
  const top = +localStorage.getItem("sidebar_scroll")
  if (el) el.scrollTop = top
}

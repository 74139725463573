import styled, { css } from "styled-components"

import { H2, H1, H3, P } from "components/Markdown/Elements"

const onlyTextStyle = css`
  border: none;
  padding: 0;
  cursor: auto;

  ${H1}, ${H2}, ${H3} {
    margin-top: 32px;
    margin-bottom: 12px;
  }

  ${P} {
    margin-bottom: 0;
  }
`

export const Card = styled.div`
  background: var(--tutorial-card-bg);
  border: 1px solid var(--tutorial-card-border);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  margin-top: 12px;
  margin-bottom: 24px;
  transition: 0.5s all;

  &[data-card] {
    cursor: pointer;
  }

  &.active {
    box-shadow: 0px 2px 8px var(--tutorial-card-shadow);
    border-color: var(--tutorial-card-bg-hover);
    background: var(--tutorial-card-bg-hover);
  }

  & > :last-child {
    margin-bottom: 0 !important;
    & > :last-child {
      margin-bottom: 0 !important;
    }
  }

  .anchor {
    display: none;
  }

  ${H1}, ${H2}, ${H3} {
    scroll-margin-top: 150px;
    font-family: Montserrat;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
    margin: 0;

    a {
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
    }
  }

  ${P} {
    margin-top: 0;
    margin-bottom: 8px;
  }

  ${props => props.onlyText && onlyTextStyle}
`

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dasha Docs","short_name":"Dasha Docs","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1161b8a0f11091060b4133a394427c8f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/app/src/intl","languages":["en-us"],"defaultLanguage":"en-us","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-autolink-headers","options":{"maintainCase":false,"removeAccents":true}},"gatsby-remark-copy-linked-files","/app/plugins/gatsby-remark-text-anchors/index.js",{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}},{"resolve":"/app/plugins/gatsby-remark-shiki/dist/index.js","options":{"theme":"./gatsby/code-theme.json","langs":[{"id":"dsl","scopeName":"source.dashaScriptingLanguage","grammar":{"version":"0.0.1","scopeName":"source.dashaScriptingLanguage","fileTypes":["dsl"],"foldingStartMarker":"\\{\\s*$","foldingStopMarker":"^\\s*\\}","patterns":[{"name":"comment.line.double-slash.dsl","match":"//.*$"},{"name":"comment.block.dsl","begin":"/\\*","end":"\\*/"},{"name":"constant.numeric.decimal.dsl","match":"[+-]?(\\d+?|\\d*\\d+)([eE]\\d+)?"},{"name":"constant.language.dsl","match":"true|false"},{"name":"string.quoted.double.dsl","begin":"\"","end":"\"|(?<!\\\\)(?=]\\s*\\n)"},{"name":"keyword.other.dsl.directive","match":"\\b(library|import|context)\\b"},{"name":"keyword.other.dsl.definition.toplevel","match":"\\b(start|node|block|digression|preprocessor|external|function|type)\\b"},{"name":"keyword.other.dsl.section","match":"\\b(do|transitions|onexit|digression|condition)\\b"},{"name":"keyword.other.dsl.transtion","match":"\\b(on|goto|priority)\\b"},{"name":"keyword.control.dsl","match":"\\b(if|on|goto|wait|done|return|blockcall|set|var)\\b"},{"name":"storage.type.dsl","match":"\\b(string|number|boolean)\\b"},{"name":"variable.other.dsl.context","match":"\\$[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.language.dsl.syscall","match":"#[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.other.dsl.literal","match":"@[a-zA-Z_][a-zA-Z0-9_]*"},{"name":"variable.other.dsl.local","match":"[a-zA-Z_][a-zA-Z0-9_]*"}]}}]}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
